import { BrandLink } from '../../brand-buttons/brand-buttons';
import type { TFunction } from 'i18next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type * as React from 'react';
import type { GetHotelHomePageQuery } from '../../../generated/types';
import {
  generateUrl,
  getEVChargingString,
  getSelfParkingString,
  getTransformedPetFeeText,
  getValetParkingString,
} from '../../../helpers/property-utils';
import type { TPoliciesList } from './PoliciesList';
import PoliciesList from './PoliciesList';
import { getLocaleComma } from '@dx-ui/framework-i18n';

export type THotelPolicies = {
  policy: NonNullable<GetHotelHomePageQuery['hotel']>['policy'] | null;
  resortPolicyText?: string | null;
  petsInfo: NonNullable<GetHotelHomePageQuery['hotel']>['pets'] | null;
  safetyInfo: NonNullable<GetHotelHomePageQuery['hotel']>['safetyAndSecurity'] | null;
  facilityInfo: NonNullable<GetHotelHomePageQuery['hotel']>['facilityOverview'] | null;
};

export const AmenityType = {
  Parking: 'parking',
  Pets: 'pet-friendly',
  NonSmoking: 'no-smoking',
  WiFi: 'wifi',
  FeesAndCharges: 'currency',
  Resort: 'resort',
} as const;

/* This is the version of the parking policy that appears on the homepage.
 * Contrast to the versions from `hotel-info` and `hotel-location` pages. */
const getParkingPolicyHomePage = (
  policy: THotelPolicies['policy'],
  language: string,
  t: TFunction<['hotel-policies']>
) => {
  const selfParking = getSelfParkingString(t, policy?.parking);
  const evCharging = getEVChargingString(t, policy?.parking, language);
  const evComments = policy?.parking?.evCharging?.chargingComments;
  const valetParking = getValetParkingString(t, policy?.parking);
  const info = policy?.parking?.otherParkingInfo ? policy?.parking?.otherParkingInfo : null;
  const securedParking = policy?.parking?.hasSecuredParking ? t('available') : t('notAvailable');
  const coveredParking = policy?.parking?.hasCoveredParking ? t('available') : t('notAvailable');
  const inOut = policy?.parking?.hasInOutParkingPrivilege ? t('available') : t('notAvailable');
  const hasSelfParking = policy?.parking?.hasSelfParking;
  const onSiteParking = policy?.parking?.hasOnsiteParking;
  const localeComma = getLocaleComma({ locale: language });

  return (
    <>
      <div
        className="mb-4 flex flex-row md:mb-6"
        data-e2e="policy-parking-self"
        data-testid="policy-parking"
      >
        <div className="mr-9 flex-1 font-bold">{t('selfParking')}</div>
        <div className="mr-9 flex-1 font-medium">
          {hasSelfParking && onSiteParking ? `${t('onSite')}, ` : null}
          {selfParking}
        </div>
      </div>
      <div
        className="mb-4 flex flex-row md:mb-6"
        data-e2e="policy-parking-valet"
        data-testid="policy-parking-valet"
      >
        <div className="mr-9 flex-1 font-bold">{t('valetParking')}</div>
        <div className="mr-9 flex-1 font-medium">{valetParking}</div>
      </div>
      <div className="mb-4 flex flex-row md:mb-6" data-testid="policy-parking-evCharging">
        <div className="mr-9 flex-1 font-bold">{t('evCharging')}</div>
        <div className="mr-9 flex-1 font-medium">
          <p>
            {evCharging}
            {evComments && localeComma}
          </p>
          {evComments ? <p>{evComments}</p> : null}
        </div>
      </div>
      <div
        className="mb-4 flex flex-row md:mb-6"
        data-e2e="policy-parking-secured"
        data-testid="policy-parking-secured"
      >
        <div className="mr-9 flex-1 font-bold">{t('secured')}</div>
        <div className="mr-9 flex-1 font-medium">{securedParking}</div>
      </div>
      <div
        className="mb-4 flex flex-row md:mb-6"
        data-e2e="policy-parking-covered"
        data-testid="policy-parking-covered"
      >
        <div className="mr-9 flex-1 font-bold">{t('covered')}</div>
        <div className="mr-9 flex-1 font-medium">{coveredParking}</div>
      </div>
      <div
        className="mb-4 flex flex-row md:mb-6"
        data-e2e="policy-parking-inout"
        data-testid="policy-parking-inout"
      >
        <div className="mr-9 flex-1 font-bold">{t('privileges')}</div>
        <div className="mr-9 flex-1 font-medium">{inOut}</div>
      </div>
      {info ? (
        <div
          className="mb-4 flex flex-row md:mb-6"
          data-e2e="policy-parking-info"
          data-testid="policy-parking-info"
        >
          <div className="mr-9 flex-1 font-bold">{t('otherParkingInfo')}</div>
          <div className="mr-9 flex-1 font-medium">{info}</div>
        </div>
      ) : null}
    </>
  );
};

const getPetsPolicy = (petsInfo: THotelPolicies['petsInfo'], t: TFunction<['hotel-policies']>) => {
  const petsAllowed = petsInfo?.petsAllowed;
  const serviceAnimalsOnly = !petsAllowed ? petsInfo?.servicePetsAllowed : null;
  const allowed = petsAllowed ? t('yes') : t('no');
  const nonRefundable =
    !!petsInfo && petsInfo.petCharge && !petsInfo.petChargeRefundable
      ? `${petsInfo.petChargeFmt} ${t('hotel-policies:nonRefundableFee')}`
      : null;
  const refundable =
    !!petsInfo && petsInfo.petCharge && !!petsInfo.petChargeRefundable
      ? `. ${petsInfo.petChargeFmt} ${t('hotel-policies:refundable')}`
      : null;

  if (serviceAnimalsOnly) {
    return (
      <div
        className="mb-4 flex flex-row md:mb-6"
        data-e2e="policy-pets-service"
        data-testid="policy-pets"
      >
        <div className="mr-9 flex-1 font-bold">{t('hotel-policies:serviceAnimals')}</div>
        <div className="mr-9 flex-1 font-medium">{t('hotel-policies:serviceAnimalsOnly')}</div>
      </div>
    );
  }

  return (
    <>
      <div
        className="mb-4 flex flex-row md:mb-6"
        data-e2e="policy-pets-allowed"
        data-testid="policy-pets"
      >
        <div className="mr-9 flex-1 font-bold">{t('hotel-policies:petsAllowed')}</div>
        <div className="mr-9 flex-1 font-medium">
          {allowed}
          {refundable}
        </div>
      </div>

      {!!petsInfo && nonRefundable ? (
        <div className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-norefund">
          <div className="mr-9 flex-1 font-bold">{t('hotel-policies:deposit')}</div>
          <div className="mr-9 flex-1 font-medium">
            {allowed}. {nonRefundable}
          </div>
        </div>
      ) : null}
      {!!petsInfo && petsInfo.petMaxWeightFmt ? (
        <div className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-weight">
          <div className="mr-9 flex-1 font-bold">{t('hotel-policies:maxWeight')}</div>
          <div className="mr-9 flex-1 font-medium">{`${petsInfo.petMaxWeightFmt} `}</div>
        </div>
      ) : null}
      {!!petsInfo && petsInfo.petMaxSize ? (
        <div className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-size">
          <div className="mr-9 flex-1 font-bold">{t('hotel-policies:maxSize')}</div>
          <div className="mr-9 flex-1 font-medium capitalize">{petsInfo.petMaxSize}</div>
        </div>
      ) : null}
      {!!petsInfo && petsInfo.otherServiceDesc ? (
        <div className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-info">
          <div className="mr-9 flex-1 font-bold">{t('hotel-policies:otherPetInfo')}</div>
          <div className="mr-9 flex-1 font-medium">
            {getTransformedPetFeeText(petsInfo.otherServiceDesc)}
          </div>
        </div>
      ) : null}
    </>
  );
};

const getSmokingPolicy = (
  policy: THotelPolicies['policy'] | null,
  safetyInfo: THotelPolicies['safetyInfo']
) => {
  const nonSmoking = safetyInfo?.hundredPctNonSmoking;
  const smokingDesc = policy?.smoking?.smokingDesc || safetyInfo?.smokingPolicyDesc;

  return (
    <p
      data-e2e={`policy-smoking-${nonSmoking ? 'no' : 'yes'}`}
      data-testid="policy-smoking"
      className="m-0"
    >
      {smokingDesc}
    </p>
  );
};

const getWifiPolicy = (
  policy: THotelPolicies['policy'] | null,
  t: TFunction<['hotel-policies']>
) => {
  const freeWlanMsg = policy?.wifi?.freeWlanMsg;
  const wlanInRoom = policy?.wifi?.wlanInRoom;
  const wlanInRoomFee = policy?.wifi?.wlanInRoomFee || 0;

  let wifiText = t('hotel-policies:notAvailable');
  if (freeWlanMsg) {
    wifiText = freeWlanMsg;
  } else {
    const isFreeWifi = wlanInRoom && !isNaN(wlanInRoomFee) && wlanInRoomFee === 0;
    if (isFreeWifi) {
      wifiText = t('hotel-policies:freeRoomAndLobbyWiFi');
    } else if (wlanInRoom && !isNaN(wlanInRoomFee) && wlanInRoomFee > 0) {
      const feeFmt = policy?.wifi?.wlanInRoomFeeFmt;
      wifiText = `${t('hotel-policies:standardRoomAndLobbyWiFi')} ${feeFmt}`;
    }
  }

  return (
    <p data-e2e="policy-wifi" data-testid="policy-wifi" className="m-0">
      {wifiText}
    </p>
  );
};

const getResortPolicy = (resortPolicyText: string | null, t: TFunction<['hotel-policies']>) => (
  <span className="text-base md:text-lg">
    <div className="mb-4 font-bold md:mb-6">{t('hotel-policies:chargesFees')}</div>
    <p data-e2e="policy-resort" data-testid="policy-resort" className="m-0">
      {resortPolicyText}
    </p>
  </span>
);
/**
 * Note: This is the version of the hotel's policies shown on the property's
 * homepage. Compare to the `hotel-location` and `hotel-info` page components.
 */
const HotelPolicies = ({
  policy,
  resortPolicyText,
  safetyInfo,
  petsInfo,
  facilityInfo,
}: THotelPolicies) => {
  const { t } = useTranslation(['hotel-policies']);
  const { relativeUrl } = generateUrl(useRouter(), 'hotel-info');
  const language = useRouter().locale || 'en';

  const getPolicyContent = (
    key: string,
    policy: THotelPolicies['policy'],
    petsInfo: THotelPolicies['petsInfo'],
    safetyInfo: THotelPolicies['safetyInfo']
  ) => {
    let policyContent: React.ReactNode = null;

    if (key === 'parking') policyContent = getParkingPolicyHomePage(policy, language, t);
    if (key === 'pets') policyContent = getPetsPolicy(petsInfo, t);
    if (key === 'smoking') policyContent = getSmokingPolicy(policy, safetyInfo);
    if (key === 'wifi') policyContent = getWifiPolicy(policy, t);

    return <div>{policyContent}</div>;
  };

  const items: TPoliciesList['items'] = [
    {
      description: getPolicyContent('parking', policy, petsInfo, safetyInfo),
      name: t('hotel-policies:parking'),
      icon: AmenityType.Parking,
    },
    {
      description: getPolicyContent('pets', policy, petsInfo, safetyInfo),
      name: t('hotel-policies:pets'),
      icon: AmenityType.Pets,
    },
    {
      description: getPolicyContent('smoking', policy, petsInfo, safetyInfo),
      name: t('hotel-policies:smoking'),
      icon: AmenityType.NonSmoking,
    },
  ];

  const wlanInRoomFeeIsNull = policy?.wifi?.wlanInRoomFee === null;
  if (!wlanInRoomFeeIsNull) {
    items.push({
      description: getPolicyContent('wifi', policy, petsInfo, safetyInfo),
      name: t('wiFi'),
      icon: AmenityType.WiFi,
    });
  }

  if (resortPolicyText) {
    const isResort = !!facilityInfo && facilityInfo.isWorldwideResort;
    const resortFeePolicies = {
      description: getResortPolicy(resortPolicyText, t),
      icon: isResort ? AmenityType.Resort : AmenityType.FeesAndCharges,
      name: isResort ? t('hotel-policies:resort') : t('hotel-policies:feesAndCharges'),
    };

    items.unshift(resortFeePolicies);
  }

  return (
    <div className="my-0 mt-4 flex flex-auto flex-col justify-center py-4 pb-0">
      <h2 className="heading-2xl lg:heading-3xl pb-4 text-center" data-testid="icon-grid-header">
        {t('hotelPolicies')}
      </h2>
      <PoliciesList items={items} />
      <div className="flex justify-center py-6">
        <BrandLink
          url={relativeUrl}
          anchorClassName="my-2"
          label={t('hotel-policies:allPolicies')}
        />
      </div>
    </div>
  );
};
export default HotelPolicies;
